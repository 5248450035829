import { Controller } from 'stimulus'

export class ReportsController extends Controller {
  remoteHTML({ route, params, target, spinner, callback }) {
    // Add locale param
    const locale = new URLSearchParams(window.location.search).get('locale')
    params ||= {}
    if (locale) params.locale = locale

    $.get(route, params, null, 'html')
      .done((html) => {
        target.innerHTML = html
        if (typeof spinner !== 'undefined') $(spinner).toggleClass('d-none')
        if (callback) callback()
      })
      .fail((e) => {
        this.alertsController.show(I18n.t('errors.general'))
      })
  }

  showModal(event) {
    const data = event.currentTarget.dataset
    this.modalsController.show({
      controller: data.delegateController,
      target: data.delegateTarget,
      metric: data.metric,
      index: data.index
    })
  }

  dispatchCustomEvent(eventName) {
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent(eventName, true, true, null)
    this.element.dispatchEvent(event)
  }

  get alertsController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller~="reports--alerts"]'),
      'reports--alerts'
    )
  }

  get modalsController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller~="reports--modals"]'),
      'reports--modals'
    )
  }

  get modalBody() { return this.modalsController.modalBody }
  get shortname() { return document.body.dataset.campaignShortname }
  get campaignId() { return document.body.dataset.campaignId }
}
