import $ from 'jquery'
import moment from 'moment'

import 'daterangepicker/daterangepicker.js'
import 'daterangepicker/daterangepicker.css'

// TODO: Extract and generalize this, and intelligently choose client's colors
import 'dnb/scss/daterangepicker_overrides.scss'

export default class MarketerDatePicker {
  constructor(dateRangeInput, startDateInput, endDateInput, dateUpdateListener, displayFormat) {
    // TODO: Find a more general place to set locale
    moment.locale(I18n.locale)

    this.dateRangeInput = dateRangeInput
    this.startDateInput = startDateInput
    this.endDateInput = endDateInput
    this.startDate = this.makeDefaultDate(this.startDateInput.val())
    this.endDate = this.makeDefaultDate(this.endDateInput.val())
    this.dateUpdateListener = dateUpdateListener
    this.displayFormat = displayFormat || 'MMMM D, YYYY'
  }

  init() {
    const hasDates = this.startDate ? true : false

    this.dateRangeInput.daterangepicker(
      {
        // Hack to allow for initial empty start/end date as per
        // Issue: https://github.com/dangrossman/daterangepicker/issues/815#issuecomment-493323964
        // Syntax: https://medium.com/@mikeh91/conditionally-adding-keys-to-javascript-objects-using-spread-operators-and-short-circuit-evaluation-acf157488ede
        ...this.startDate && {startDate: this.startDate},
        ...this.endDate && {endDate: this.endDate},
        autoUpdateInput: hasDates,
        //
        timePicker: false,
        alwaysShowCalendars: true,
        showCustomRangeLabel: true,
        ranges: this.ranges(),
        locale: {
          monthNames: I18n.t('daterangepicker.month_labels').split(','),
          daysOfWeek: I18n.t('daterangepicker.weekday_labels').split(','),
          firstDay: 1,
          applyLabel: I18n.t('daterangepicker.apply'),
          cancelLabel: I18n.t('daterangepicker.cancel'),
          format: 'DD/MM/YYYY',
          customRangeLabel: I18n.t('daterangepicker.ranges.custom')
        }
      }
    )

    // Initial formatting
    const picker = this.dateRangeInput.data('daterangepicker')
    if (hasDates) {
      picker.element.html(picker.startDate.format(this.displayFormat) + ' - ' + picker.endDate.format(this.displayFormat))
    } else {
      picker.element.html(I18n.t('daterangepicker.choose_dates'))
    }

    this.dateRangeInput.on('apply.daterangepicker', (e, picker) => {
      this.userIntendsToApply = true
      // Hack to allow for initial empty start/end date
      picker.element.html(picker.startDate.format(this.displayFormat) + ' - ' + picker.endDate.format(this.displayFormat))

      let wasUpdated = this.updateHiddenInputs(picker)

      if (wasUpdated && this.dateUpdateListener !== undefined) {
        this.dateUpdateListener.dateWasUpdated()
      }

    })

    this.dateRangeInput.on('hide.daterangepicker', (e, picker) => {
      setTimeout(() => {
        if (!this.userIntendsToApply) {
          picker.startDate = picker.oldStartDate
          picker.endDate = picker.oldEndDate
        }

        this.userIntendsToApply = false
      }, 100)
    })
  }

  ranges() {
    var collection = {}
    collection[I18n.t('daterangepicker.ranges.today')] = [moment(), moment()]
    collection[I18n.t('daterangepicker.ranges.yesterday')] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
    collection[I18n.t('daterangepicker.ranges.last_7_days')] = [moment().subtract(6, 'days'), moment()]
    collection[I18n.t('daterangepicker.ranges.last_14_days')] = [moment().subtract(13, 'days'), moment()]
    collection[I18n.t('daterangepicker.ranges.last_30_days')] = [moment().subtract(29, 'days'), moment()]
    collection[I18n.t('daterangepicker.ranges.this_month')] = [moment().startOf('month'), moment().endOf('month')]
    collection[I18n.t('daterangepicker.ranges.last_month')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    return collection
  }

  makeDefaultDate(date) {
    let momentDate = moment(date, 'YYYY-MM-DD Z')

    if (momentDate.isValid()) {
      return momentDate
    } else {
      return ''
    }
  }

  updateHiddenInputs(dateRangePicker) {
    let newStartDate = dateRangePicker.startDate.format(this.format)
    let newEndDate = dateRangePicker.endDate.format(this.format)
    if (this.startDateInput.val() == newStartDate && this.endDateInput.val() == newEndDate) return false

    this.startDateInput.val(newStartDate)
    this.endDateInput.val(newEndDate)
    this.startDateInput.trigger('change')
    return true
  }
}
