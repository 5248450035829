import {ReportsController} from './reports_controller'
import MarketerChart from '../../marketer-chart/standard'
import 'bootstrap/js/dist/tooltip'
import { reports_details_ad_path, reports_switch_ad_path, reports_external_link_path } from 'routes.js.erb'

export default class extends ReportsController {
  connect() {
    let index = this.modalBody[0].dataset.index
    this.displayAd(undefined, index !== undefined ? index : 0)
  }

  displayAd(event, index) {
    this.currentIndex = index !== undefined ? index : event.currentTarget.dataset.index
    this.count = parseInt(document.getElementById('overview-ads').dataset.adCount)

    this.remoteHTML({
      route: reports_details_ad_path({
        shortname: this.shortname,
      }),
      params: {
        index: this.currentIndex,
        count: this.count
      },
      target: this.modalBody[0],
      callback: () => {
        this.dispatchCustomEvent('modal-loaded')
        this.enableCarousel()
        this.initChart(this.currentIndex)
        $('[data-toggle="tooltip"]').tooltip()
      }
    })
  }

  enableCarousel() {
    $('[data-carousel="ad"]').slick({
      arrows: true,
      prevArrow: $('#left-arrow'),
      nextArrow: $('#right-arrow'),
      dots: true,
      initialSlide: parseInt(this.currentIndex),
      infinite: false,
    }).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      /*
       * This timeout exists to allow carousel animation to finish before we
       * clear the slot after the previously displayed element. We need to clear
       * it in order to remove any video elements that could be still playing
       * the content.
       */
      const carouselAnimationDuration = 1000
      setTimeout(() => { this.clearAd(currentSlide) }, carouselAnimationDuration)
      this.switchAd(nextSlide)
    })
  }

  clearAd(adIndex) {
    $(`[data-ad-index="${adIndex}"]`).html('')
  }

  switchAd(adIndex) {
    this.remoteHTML({
      route: reports_switch_ad_path({
        shortname: this.shortname,
      }),
      params: {
        index: adIndex
      },
      target: $(`[data-ad-index="${adIndex}"]`)[0],
      callback: () => {
        this.initChart(adIndex)
        $('[data-toggle="tooltip"]').tooltip()
      }
    })
  }

  enableChart() {
    $('.chart-list-item').slideDown()
    $('.chart-enabler').hide()
    $('.modal .line-bottom').last().removeClass('line-bottom')
  }

  openExternalLink(event) {
    let index = event.currentTarget.dataset.index
    $.get(reports_external_link_path(this.shortname), {index: index}, null, 'json')
      .done((response) => {
        window.open(response.link)
      })
      .fail((e) => {
        this.alertsController.show(I18n.t('errors.general'))
      })
  }

  playVideo(event) {
    event.stopPropagation()

    const placeholder = $(event.currentTarget)
    const previewElement = $('.video-preview-container', placeholder.parent())
    const player = $('video', previewElement)[0]
    player.width = placeholder.width()
    player.height = placeholder.height()

    placeholder.hide();
    previewElement.show();
    player.play();
  }

  initChart(adIndex) {
    let chart = new MarketerChart(`ad-chart-${adIndex}`)
    setTimeout(() => {
      let lastElementIndex = chart.getDatasetMeta(0).data.length - 1
      chart.openTooltip(lastElementIndex)
    }, 700)
  }
}
