import {Controller} from 'stimulus'
import $ from "jquery"
import { agent_promos_campaigns_path } from 'routes.js.erb'

export default class ListController extends Controller {
  static targets = ['dashboard']

  connect() {
    this.refresh({})
  }

  refresh(params) {
    params['request_guid'] = this.generateAndSaveRequestGuid()

    this.setSpinner()

    $.get(agent_promos_campaigns_path(), params, 'html')
      .done((html) => {

        if (this.verifyResponseIsToLatestRequest(html)) {
          this.dashboardTarget.innerHTML = html
          this.setPagination()
        }
      })
      .fail((e) => {
        console.log('build view failed')
      })
  }

  generateAndSaveRequestGuid() {
    let requestGuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    this.dashboardTarget.dataset.lastRequestGuid = requestGuid

    return requestGuid
  }

  // Protects against race conditions
  verifyResponseIsToLatestRequest(html) {
    return html.includes(this.dashboardTarget.dataset.lastRequestGuid)
  }

  setSpinner() {
    this.dashboardTarget.innerHTML = '<p class="text-center"><i class="fas fa-circle-notch fa-2x fa-spin mt-5"></i></p>'
  }

  setPagination() {
    $('.page-link').click((event) => {
      event.preventDefault()
      this.setActiveLink($(event.currentTarget))

      let page = $('.page-item.active:first .page-link').html()

      this.refresh({
        page: page ? page : 1
      })
    })
  }

  setActiveLink(clickedLink) {
    if (clickedLink.parent().hasClass('next')) {
      clickedLink = $('.page-item.active:first').next().children().first()
    } else if (clickedLink.parent().hasClass('prev')) {
      clickedLink = $('.page-item.active:first').prev().children().first()
    }

    $('.page-item').removeClass('active')
    clickedLink.parent().addClass('active')
  }
}
