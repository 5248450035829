import 'image-picker/image-picker/image-picker.css'
import 'image-picker/image-picker/image-picker.min'

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'form', 'imagePicker']

  connect() {
    this.modal.on('show.bs.modal', this.initPicker())
  }

  teardown() {
    this.modal.off('show.bs.modal', this.initPicker())
    let picker = this.imagePicker.data('picker')
    if (typeof picker !== 'undefined') picker.destroy()
  }

  initPicker() {
    let picker = this.imagePicker.data('picker')
    if (typeof picker !== 'undefined') return

    this.imagePicker.imagepicker(
      {
        limit: this.maxCount,
        limit_reached: () => {
          alert(this.maxCountMessage)
        }
      }
    )

    $('img.image_picker_image').attr('width', '100')
    this.select2init = true
  }

  submitSucceeded() { location.reload() }

  submitFailed() { alert(I18n.t('errors.general')) }

  get modal() { return $(this.modalTarget) }
  get imagePicker() { return $(this.imagePickerTarget) }
  get maxCount() { return this.imagePicker.data('max-count') }
  get form() { return $(this.formTarget) }

  // TODO: Localize this
  get maxCountMessage() {
    return 'Du kan maks velge ' + this.maxCount + ' bilder. Du kan trykke på et valgt bilde igjen for å fjerne det.'
  }
}
