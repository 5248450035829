$(document).on('turbolinks:load', () => {
  $('body').on('click', '.toggler-form-edit, .toggler-form-edit-cancel', function() {
    var element = $(this),
        togglerFormEdit, wasCancel = false

    if (element.hasClass('toggler-form-edit')) {
      togglerFormEdit = element
    } else if (element.hasClass('toggler-form-edit-cancel')) {
      wasCancel = true
      togglerFormEdit = element.siblings('.toggler-form-edit').last()
    }

    const form = $(togglerFormEdit.data('target'))

    if (!wasCancel && form.hasClass('active')) {
      form.trigger('submit.rails')
      return
    }

    if (wasCancel) form.trigger('reset')

    toggleCardFormEdit(form)
  })

  $('.card-form').on('ajax:success', function(evt, data, status, xhr) {
    handleCardFormSubmitSuccess(this)
  }).on('ajax:beforeSend', function(evt, xhr, settings) {
    console.log('Validate')
  }).on('ajax:error', function(evt, xhr, status, error) {
    console.log('Error: ' + error)

    if (xhr.responseJSON.location) {
      window.location.href = xhr.responseJSON.location
      return
    }

    alert(I18n.t('errors.general'))
  })

  $('form input[type=text], form input[type=tel], form textarea').on('focus', function(e) {
    var element = $(this)
    element.removeClass('form-control-danger')
    $(element.closest('.form-group')).removeClass('has-danger')
  })
})

$.fn.filterByData = function(key, value) { return this.filter(function() { return $(this).data(key) == value } ) }

function toggleCardFormEdit(form) {
	var form = $(form),
			togglerFormEdit = $('.toggler-form-edit').filterByData('form', form.id).last(),
			togglerFormCancel = togglerFormEdit.siblings('.toggler-form-edit-cancel').last(),
			togglerCollapse = togglerFormEdit.siblings('.toggler-collapse').last(),
			togglerCollapseTarget = $(togglerCollapse.data('target'))

	if (togglerCollapseTarget.attr('aria-expanded') != true) { togglerCollapseTarget.collapse('show') }

	togglerFormCancel.prop('hidden', !togglerFormCancel.prop('hidden'))
	togglerCollapse.prop('hidden', !togglerCollapse.prop('hidden'))
	togglerFormEdit.toggleClass('icon-edit-1').toggleClass('icon-check')
	form.toggleClass('active')

	$('.form-control-toggleable', form).each(function() {
		var element = $(this)
		element.prop('readonly', !element.prop('readonly'))
		element.toggleClass('active')
	})
}

function handleCardFormSubmitSuccess(form) {
	$('.form-control-toggleable', form).each(function() { this.setAttribute('value', this.value) })
	toggleCardFormEdit(form)
}

function resetForm(form) {
	$('input', form).each(function(index, element) {
		var element = $(element)
		element.removeClass('form-control-danger')
		$(element.closest('.form-group')).removeClass('has-danger')
	})
}

function validatePhoneNumber(number) {
	return /^[2345679]+[0-9]+$/.test(number)
}

function validateMobileNumber(number) {
	if (number.length != 8) return false
	return /^[49]+[0-9]+$/.test(number)
}

function validateEmail(email) {
	var emailReg = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
	return emailReg.test(email)
}

function validateFormField(field, type) {
	var field = $(field),
			fieldVal = field.val(),
			isValid = true
	if (fieldVal == '') {
		isValid = false
	} else {
		switch(type) {
			case 'phone':
				if (!validatePhoneNumber(fieldVal)) isValid = false
				break
		  case 'mobile':
				if (!validateMobileNumber(fieldVal)) isValid = false
				break
			case 'email':
				if (!validateEmail(fieldVal)) isValid = false
				break
		}
	}
	return isValid
}

