import { Controller } from 'stimulus';
import 'select2/dist/css/select2'
import 'select2/dist/js/select2'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'

export default class extends Controller {
  static values = {
    url: String,
    placeholder: String
  }

  connect() {
    $(this.element).select2({
      theme: 'bootstrap4',
      placeholder: this.placeholderValue,
      allowClear: true,
      ajax: {
        url: this.urlValue,
        dataType: 'json',
        delay: 300,
        data: (params) => {
          return { search: params.term, page: params.page || 1 };
        },
        processResults: (data, params) => {
          params.page = params.page || 1;

          return {
            results: data.items,
            pagination: {
              more: (params.page * 25) < data.count
            }
          };
        }
      }
    })
  }
}
