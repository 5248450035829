import { ReportsController } from './reports_controller'
import { reports_overview_ads_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['ad', 'spinner']

  connect() {
    this.remoteHTML({
      route: reports_overview_ads_path({ shortname: this.shortname }),
      target: this.adTarget,
      spinner: this.spinnerTarget
    })
  }
}
