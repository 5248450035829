import { ReportsController } from './reports_controller'
import { reports_plugins_doorling_index_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['doorling', 'spinner', 'map']

  connect() {
    this.remoteHTML({
      route: reports_plugins_doorling_index_path({shortname: this.shortname}),
      target: this.doorlingTarget,
      spinner: this.spinnerTarget
    })
  }
}
