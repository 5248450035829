import { ReportsController } from './reports_controller'
import 'bootstrap/js/dist/alert'

export default class extends ReportsController {
  static targets = ['alert', 'message']

  show(message) {
    this.setMessage(message)
    this.alert.removeClass('invisible')
  }

  hide() {
    this.alert.addClass('invisible')
    this.removeMessage()
  }

  setMessage(message) {
    this.message.html(message)
  }

  removeMessage() {
    this.message.empty()
  }

  get alert() {
    return $(this.alertTarget)
  }

  get message() {
    return $(this.messageTarget)
  }
}
