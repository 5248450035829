(function(window, $) {
	'use strict';
	function InfiniteScroller(element, options, completionHandler) {
		if (!(this instanceof InfiniteScroller)) return new InfiniteScroller(element, options, completionHandler);
		var _this = this,
				_options = options,
				spinner = document.createElement('div');
		spinner.classList.add('spinner');
		spinner.innerHTML = '<i class="fas fa-circle-notch fa-2x fa-spin"></i>';
		_this.element = element;
		_this.url = options.url;
		_this.currentPage = 1;
		_this.spinner = spinner;
		_this.completionHandler = completionHandler;
		element.infiniteScroller = _this;

		function _bind() {
			var supportsPassive = false;
			try {
				var opts = Object.defineProperty({}, 'passive', {
					get: function() { supportsPassive = true; }
				});
				window.addEventListener('test', null, opts);
			} catch (e) {}
			_this.scrollHandler = throttle(_handleScroll, null, _this);
			window.addEventListener('scroll', _this.scrollHandler, supportsPassive ? { passive: true } : false);
		}

		function throttle(fn, threshhold, scope) {
			threshhold || (threshhold = 250);
			var last,
					deferTimer;
			return function() {
				var context = scope || this,
						now = +new Date,
						args = arguments;
				if (last && now < last + threshhold) {
					clearTimeout(deferTimer);
					deferTimer = setTimeout(function() {
						last = now;
						fn.apply(context, args);
					}, threshhold + last - now);
				} else {
					last = now;
					fn.apply(context, args);
				}
			};
		}

		function _handleScroll() {
			var $window = $(window),
					url = _this.url;
			if (url && $window.scrollTop() > $(document).height() - $window.height() - 50) {
				// TODO: Improve this
				if (!_this.requestInProgress) {
					_this.requestInProgress = true;
					$.ajax(url, {
						data: { current_page: _this.currentPage },
						beforeSend: function() { _this.element.appendChild(spinner); },
						success: function(response, status, xhr) {
							_this.requestInProgress = false;
							_this.element.removeChild(spinner);
							if (response && response.data) _this.element.insertAdjacentHTML('beforeend', response.data);
							_this.currentPage += 1;
							// TODO: Improve this
							if (!(response && response.meta && response.meta.pagination && response.meta.pagination.next)) _destroy();
							if (_this.completionHandler != null) _this.completionHandler();
						},
						error: function(evt, xhr, status, error) {
							_this.requestInProgress = false;
							_this.element.removeChild(spinner);
							// TODO: Handle errors here
							console.log('Error: ' + error);
							if (xhr.responseJSON.location) {
								window.location.href = xhr.responseJSON.location;
								return;
							}
							alert(I18n.t('errors.general'))
						}
					});
				}
			}
		}

		function _unbind() { window.removeEventListener('scroll', _this.scrollHandler); }

		function _destroy() {
			_unbind();
			_this.element.infiniteScroller = undefined;
		}
		InfiniteScroller.prototype.destroy = function() { return _destroy(); };

		_bind();

		return _this;
	}
	if (typeof(window.InfiniteScroller) === 'undefined') window.InfiniteScroller = InfiniteScroller;
})(window, jQuery);
