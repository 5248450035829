import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form']

  handleSubmit(event) {
    Rails.stopEverything(event);

    const pathname = this.formTarget.getAttribute('action');
    const formData = new FormData(this.formTarget);
    const searchParams = new URLSearchParams(
      Array
        .from(formData.entries())
        .filter((el) => el[1] !== '')
    ).toString();
    const url = `${pathname}?${searchParams}`

    Turbolinks.visit(url);
  }

  handleSelectChange() {
    Rails.fire(this.formTarget, 'submit');
  }
}
