import { Controller } from 'stimulus'
import $ from 'jquery'
import { admin_orders_path } from 'routes.js.erb'

export default class ListController extends Controller {
  static targets = ['list']

  connect() {
    this.refresh({})
  }

  refresh(params) {
    params['request_guid'] = this.generateAndSaveRequestGuid()

    this.setSpinner()

    $.get(admin_orders_path(), params, 'html')
      .done((html) => {
        if (this.verifyResponseIsToLatestRequest(html)) {
          this.listTarget.innerHTML = html
          this.setPagination()
        }
      })
      .fail((e) => {
        console.log('build view failed')
      })
  }

  generateAndSaveRequestGuid() {
    let requestGuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    this.listTarget.dataset.lastRequestGuid = requestGuid

    return requestGuid
  }

  // Protects against race conditions
  verifyResponseIsToLatestRequest(html) {
    return html.includes(this.listTarget.dataset.lastRequestGuid)
  }

  setSpinner() {
    this.listTarget.innerHTML = '<p class="text-center"><i class="fas fa-circle-notch fa-2x fa-spin mt-5"></i></p>'
  }

  setPagination() {
    $('.page-link').click((event) => {
      event.preventDefault()
      this.setActiveLink($(event.currentTarget))

      let filtersController = this.application.getControllerForElementAndIdentifier(
        document.querySelector('[data-controller~="admin--orders--filters"]'),
        'admin--orders--filters'
      )

      filtersController.submitForm()
    })
  }

  setActiveLink(clickedLink) {
    if (clickedLink.parent().hasClass('next')) {
      clickedLink = $('.page-item.active:first').next().children().first()
    } else if (clickedLink.parent().hasClass('prev')) {
      clickedLink = $('.page-item.active:first').prev().children().first()
    }

    $('.page-item').removeClass('active')
    clickedLink.parent().addClass('active')
  }
}
