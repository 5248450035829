import {Controller} from 'stimulus'
import $ from "jquery"

export default class SortController extends Controller {

  connect() {
    this.attachSortControls()
  }

  attachSortControls() {
    $('.sort-column').on('click', event => {
      let clickedElement = event.currentTarget.querySelector('[data-sort-toggler]')
      let isPointingDown = clickedElement.classList.contains('fa-sort-down')

      for (let element of document.querySelectorAll('[data-sort-toggler]')) {
        element.classList.remove('fa-sort-down')
        element.classList.remove('fa-sort-up')
        element.classList.remove('fa')
      }

      clickedElement.classList.add('fa')
      let filtersController = this.application.getControllerForElementAndIdentifier(
        document.querySelector('[data-controller~="campaigns--filters"]'),
        'campaigns--filters'
      )

      if (isPointingDown) {
        clickedElement.classList.add('fa-sort-up')
        filtersController.updateSortFields(clickedElement.dataset.key, 'asc')
      } else{
        clickedElement.classList.add('fa-sort-down', 'asc')
        filtersController.updateSortFields(clickedElement.dataset.key, 'desc')
      }
    })
  }
}
