import 'chart.js'
import 'chartjs-plugin-style'
import 'chartjs-plugin-zoom'
import moment from 'moment'

export default class MarketerChart extends Chart {
  constructor(canvas_id, animationDuration) {
    let canvas = document.getElementById(canvas_id)
    let chartData = JSON.parse(canvas.dataset.chartData)
    let chartLabels = canvas.dataset.chartLabels.split('|')
    let chartMin = parseInt(canvas.dataset.chartMin)
    let chartMax = parseInt(canvas.dataset.chartMax)
    let chartType = canvas.dataset.chartType
    let chartAxesType = canvas.dataset.chartAxesType !== undefined ? canvas.dataset.chartAxesType : 'time'
    let chartOptions = canvas.dataset.chartOptions !== undefined ? JSON.parse(canvas.dataset.chartOptions) : {}
    moment.locale(chartOptions.locale !== undefined ? chartOptions.locale : 'nb-NO')
    chartOptions.timeMin = moment(chartOptions.timeMin).startOf('day').subtract(12, 'hours')
    chartOptions.timeMax = moment(chartOptions.timeMax).startOf('day').add(12, 'hours')

    Chart.defaults.global.defaultFontFamily = chartOptions.font !== undefined ? chartOptions.font : 'Helvetica'

    if (chartOptions.customTooltip) {
      MarketerChart.setupCustomTooltip(chartOptions, canvas)
    } else {
      MarketerChart.setupTooltip(chartOptions, canvas)
    }

    super(canvas, {
      type: chartType,
      data: {
        labels: chartLabels,
        datasets: chartData
      },
      options: {
        animation: {duration: animationDuration !== undefined ? animationDuration : 1000},
        layout: {
          padding: {
            right: chartOptions.paddingRight !== undefined ? chartOptions.paddingRight : 0
          }
        },
        tooltips: MarketerChart.setTooltipOptions(chartOptions),
        fullWidth: true,
        responsive: true,
        aspectRatio: chartOptions.aspectRatio !== 'undefined' ? chartOptions.aspectRatio : 2,
        maintainAspectRatio: canvas_id != 'chart-traffic', // TODO: Improve/remove this
        plugins: {
          zoom: {
            zoom: {
              enabled: chartOptions.zoomEnabled !== 'undefined' ? chartOptions.zoomEnabled : false,
              mode: 'x',
              speed: 0.05
            },
            pan: {
              enabled: chartOptions.panEnabled !== 'undefined' ? chartOptions.panEnabled : false,
              mode: 'x'
            }
          }
        },
        legend: {
          labels: {
            fontSize: 12,
            fontWeight: 600,
            fontColor: '#15555a',
            boxWidth: 60,
            boxHeight: 2
          },
          display: chartOptions.legend !== 'undefined' ? chartOptions.legend : false,
          position: 'bottom'
        },
        scales: {
          yAxes: [{
            stacked: chartOptions.stacked !== 'undefined' ? chartOptions.stacked : false,
            gridLines: {
              display: chartOptions.yGridLines !== 'undefined' ? chartOptions.yGridLines : false,
              drawBorder: false,
              color: chartOptions.yGridLinesColor !== 'undefined' ? chartOptions.yGridLinesColor : null
            },
            ticks: {
              min: chartMin,
              max: chartMax,
              display: chartOptions.yDisplayticks !== 'undefined' ? chartOptions.yDisplayTicks : false,
              stepSize: chartOptions.stepSize || null,
              callback: (value) => {
                return value.toLocaleString('en-US').replace(',', ' ')
              }
            }
          }],
          xAxes: [{
            barPercentage: 0.7,
            categoryPercentage: 0.7,
            stacked: chartOptions.stacked !== 'undefined' ? chartOptions.stacked : false,
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: chartOptions.xDisplayticks !== 'undefined' ? chartOptions.xDisplayTicks : false,
              min: chartOptions.timeMin !== 'undefined' ? chartOptions.timeMin : null,
              max: chartOptions.timeMax !== 'undefined' ? chartOptions.timeMax : null
            },
            type: chartAxesType,
            time: {
              unit: 'day',
              displayFormats: {
                day: chartOptions.locale == 'en-US' ? 'MM.DD' : 'DD.MM'
              }
            }
          }]
        }
      }
    })
  }

  static setupCustomTooltip(chartOptions, canvas) {
    // Not supported for now
  }

  static setTooltipOptions(chartOptions){
    return chartOptions.tooltips !== 'undefined' ? chartOptions.tooltips : {}
  }

  static setupTooltip(chartOptions, canvas) {
    chartOptions.tooltips.callbacks = {}
    if (chartOptions.tooltips.formatDateLabel) {
      chartOptions.tooltips.callbacks.title = (Tooltip, data) => {
        let timeLabel = Tooltip[0].xLabel

        let format = chartOptions.locale == 'en-US' ? 'MMM DD' : 'DD MMM'
        let formattedTime = moment(timeLabel).format(format)
        return formattedTime
      }
    }

    if (chartOptions.tooltips.formatNumbers) {
      chartOptions.tooltips.callbacks.label = (Tooltip, data) => {
        let labelSuffix = canvas.dataset.chartOptions.labelSuffix == undefined ? '' : canvas.dataset.chartOptions.labelSuffix
        let value = [Tooltip.yLabel.length == 0 ? data.datasets[0].data[Tooltip.index] : Tooltip.yLabel]
        let labelArray = [`${value.toLocaleString().replace(',', ' ')} ${labelSuffix}`.trim()]

        if (chartOptions.tooltips.addLabel) {
          labelArray.push(data.datasets[Tooltip.datasetIndex].label)
        }

        let labelPrefix = chartOptions.labelPrefix == undefined ? null : `${data.labels[Tooltip.index]}`
        if (labelPrefix != null) {
          labelArray.unshift(labelPrefix)
        }

        return labelArray
      }
    }
  }

  openTooltip(index) {
    let meta = this.getDatasetMeta(0)
    let rectangle = this.canvas.getBoundingClientRect()
    let point = meta.data[index].getCenterPoint()

    let mouseMoveEvent = new MouseEvent('mousemove', {
      clientX: rectangle.left + point.x,
      clientY: rectangle.top + (point.y || 0)
    })

    this.canvas.dispatchEvent(mouseMoveEvent)
  }
}
