import { ReportsController } from './reports_controller'
import MarketerChart from '../../marketer-chart/standard'
import { reports_details_traffic_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['traffic', 'spinner']

  connect() {
    this.remoteHTML({
      route: reports_details_traffic_path({shortname: this.shortname}),
      target: this.modalBody[0],
      callback: () => {
        this.dispatchCustomEvent('modal-loaded')
        let chart = new MarketerChart('traffic-details-chart')
        this.setupChartLegend(chart)
      }
    })
  }


  setupChartLegend(chart) {
    let legend = document.getElementById('traffic-modal-legend')
    legend.innerHTML = chart.generateLegend()

    let legendItems = document.querySelectorAll('#traffic-modal-legend li')
    for (let [index, item] of legendItems.entries()) {
      item.onclick = () => {
        let isHidden = !chart.getDatasetMeta(index).hidden
        chart.getDatasetMeta(index).hidden = isHidden

        if (isHidden) {
          item.classList.add('deactivated')
        } else {
          item.classList.remove('deactivated')
        }

        chart.update()
      }
    }
  }
}
