import { Controller } from 'stimulus';

// Temporary code to wire up buttons to devops controller
export default class extends Controller {
  static targets = ['campaignId', 'output']

  request(endpoint) {
    const campaignId = this.campaignIdTarget.value;
    const output = this.outputTarget;

    fetch(`/admin/dev_ops/${endpoint}?campaign_id=${campaignId}`, {
      method: 'GET',
      headers: { 'Accept': 'text/plain' }
    })
    .then(data => {
      return data.text();
    })
    .then(text => {
      output.textContent = text;
    });
  }

  campaignDebug() { this.request('campaign_debug') }
  campaignRecover() { this.request('campaign_recover') }
  syncMetrics() { this.request('sync_metrics') }
}
