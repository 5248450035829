import $ from 'jquery'
import 'daterangepicker/daterangepicker.js'
import MarketerDatePicker from "../../marketer-date-picker"
import {Controller} from 'stimulus'
import { list_offices_campaign_filters_path, list_brands_campaign_filters_path, list_categories_campaign_filters_path, list_brokers_campaign_filters_path, list_campaigns_campaign_filters_path } from 'routes.js.erb'

export default class FiltersController extends Controller {
  static targets = ['form', 'officeFilter', 'brandFilter', 'categoryFilter', 'brokerFilter', 'campaignFilter', 'dateToField',
    'dateFromField', 'dateToExportField', 'dateFromExportField', 'typeField', 'dateRange', 'orderby', 'direction',
    'statesExportField', 'categoryExportField', 'directionExportField', 'orderbyExportField', 'brokerIdsExportField',
    'officeIdsExportField', 'campaignExportField', 'contentStatesExportField', 'submitExport'
  ]

  connect() {
    this.initCalendar()
    this.initStatesSelect()
    this.initOfficesSelect()
    this.initBrandsSelect()
    this.initCategoriesSelect()
    this.initBrokersSelect()
    this.initCampaignsSelect()
    this.setupSubmit()
  }

  setupSubmit() {
    $(this.formTarget).on('submit', (event) => {
      event.preventDefault()
      this.submitForm()
    })
  }

  updateExportFields() {
    if(this.hasDateFromExportFieldTarget) {
      this.dateFromExportFieldTarget.value = this.dateFromFieldTarget.value
    }

    if(this.hasDateToExportFieldTarget) {
      this.dateToExportFieldTarget.value = this.dateToFieldTarget
    }
  }

  updateSortFields(orderby, direction) {
    this.orderbyTarget.value = orderby
    this.directionTarget.value = direction
    this.updateOrderByExportField(orderby);
    this.updateDirectionExportField(direction);
    this.submitForm()
  }

  updateOrderByExportField(orderby) {
    if(this.hasOrderbyExportFieldTarget) {
      this.orderbyExportFieldTarget.value = orderby;
    }
  }

  updateDirectionExportField(direction) {
    if(this.hasDirectionExportFieldTarget) {
      this.directionExportFieldTarget.value = direction;
    }
  }

  submitForm() {
    let listController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller~="campaigns--list"]'),
      'campaigns--list'
    )

    let page = $('.page-item.active:first .page-link').html()

    listController.refresh({
      date_from: this.dateFromFieldTarget.value,
      date_to: this.dateToFieldTarget.value,
      orderby: this.orderbyTarget.value,
      direction: this.directionTarget.value,
      states: this.fetchStates(),
      content_states: this.fetchContentStates(),
      office_ids: this.retrieveIds(this.officeFilterTarget),
      brand_ids: this.retrieveIds(this.brandFilterTarget),
      category_ids: this.retrieveIds(this.categoryFilterTarget),
      broker_ids: this.retrieveIds(this.brokerFilterTarget),
      campaign_ids: this.retrieveIds(this.campaignFilterTarget),
      page: page ? page : 1
    })
    this.reEnableExportSubmit();
  }

  retrieveIds(target) {
    let ids = []
    for (let option of target.children) {
      if (option.classList.contains('selector-active')) {
        ids.push(option.dataset.id)
      }
    }
    return ids
  }

  reEnableExportSubmit() {
    if(this.hasSubmitExportTarget) {
      $.rails.enableElement(this.submitExportTarget)
    }
  }

  setSwitcher(event) {
    event.preventDefault()
    $('.group-selector .dropdown-item').removeClass('active')
    $(event.currentTarget).addClass('active')
    $('.group-selector .badge').text(event.currentTarget.value.toLowerCase())

    this.submitForm()
  }

  initCalendar() {
    new MarketerDatePicker($(this.dateRangeTarget), $(this.dateFromFieldTarget), $(this.dateToFieldTarget)).init()
    $(this.dateToFieldTarget).on('change', () => {
      this.updateExportFields()
      this.submitForm()
    })

    $(this.dateFromFieldTarget).on('change', () => {
      this.updateExportFields()
      this.submitForm()
    })
  }

  initStatesSelect() {
    $('[data-state-selector]').click((e) => {
      e.preventDefault()
      e.stopPropagation()

      this.toggleState($(e.currentTarget))
      this.submitForm()

      this.updateStatesExportField();
    })

    $('[data-content-state-selector]').click((e) => {
      e.preventDefault()
      e.stopPropagation()
      this.toggleContentState($(e.currentTarget))
      this.submitForm()
      this.updateContentStatesExportField();
    })
  }

  updateStatesExportField() {
    if(this.hasStatesExportFieldTarget) {
      this.statesExportFieldTarget.value = JSON.stringify(this.fetchStates())
    }
  }

  updateContentStatesExportField() {
    if(this.hasContentStatesExportFieldTarget) {
      this.contentStatesExportFieldTarget.value = JSON.stringify(this.fetchContentStates())
    }
  }

  initOfficesSelect() {
    if(this.officeFilterTarget.dataset.disabled){
      return
    }

    this.updateOfficeOptions({office_ids: this.retrieveIds(this.officeFilterTarget)})
    $(this.officeFilterTarget.parentElement.parentElement).on('hide.bs.dropdown', () => {
      if (this.officeFilterTarget.parentElement.parentElement.dataset.hasChanged == "true") {
        const officeIds = this.retrieveIds(this.officeFilterTarget)
        const brokerIds = this.retrieveIds(this.brokerFilterTarget)

        this.updateBrokerOptions({ office_ids: officeIds })
        this.updateCampaignOptions({ broker_ids: brokerIds })

        this.updateOfficeIdsExportField(officeIds);
        this.updateBrokerIdsExportField(brokerIds);

        this.submitForm()
      }
      this.officeFilterTarget.parentElement.parentElement.dataset.hasChanged = false
    })
  }

  updateOfficeIdsExportField(officeIds) {
    if(this.hasOfficeIdsExportFieldTarget) {
      this.officeIdsExportFieldTarget.value = JSON.stringify(officeIds);
    }
  }

  updateBrokerIdsExportField(brokerIds) {
    if(this.hasBrokerIdsExportFieldTarget) {
      this.brokerIdsExportFieldTarget.value = JSON.stringify(brokerIds);
    }
  }

  initBrandsSelect() {
    if(this.brandFilterTarget.dataset.disabled) {
      return
    }

    this.updateBrandOptions({brand_ids: this.retrieveIds(this.brandFilterTarget)})
    $(this.brandFilterTarget.parentElement.parentElement).on('hide.bs.dropdown', () => {
      if (this.brandFilterTarget.parentElement.parentElement.dataset.hasChanged == "true") {
        this.submitForm()
      }
      this.brandFilterTarget.parentElement.parentElement.dataset.hasChanged = false
    })
  }

  initCategoriesSelect() {
    if(this.categoryFilterTarget.dataset.disabled) {
      return
    }
    this.updateCategoryOptions({ category_ids: this.retrieveIds(this.categoryFilterTarget) })
    $(this.categoryFilterTarget.parentElement.parentElement).on('hide.bs.dropdown', () => {
      if (this.categoryFilterTarget.parentElement.parentElement.dataset.hasChanged == "true") {
        this.submitForm();
        this.updateCategoryExportField();
      }
      this.categoryFilterTarget.parentElement.parentElement.dataset.hasChanged = false
    })
  }

  updateCategoryExportField() {
    if(this.hasCategoryExportFieldTarget) {
      this.categoryExportFieldTarget.value = JSON.stringify(this.retrieveIds(this.categoryFilterTarget));
    }
  }

  initBrokersSelect() {
    if(this.brokerFilterTarget.dataset.disabled){
      return
    }

    this.updateBrokerOptions()
    $(this.brokerFilterTarget.parentElement.parentElement).on('hide.bs.dropdown', () => {
      if (this.brokerFilterTarget.parentElement.parentElement.dataset.hasChanged == "true") {
        const brokerIds = this.retrieveIds(this.brokerFilterTarget)
        this.updateCampaignOptions({ broker_ids: brokerIds })
        this.updateBrokerIdsExportField(brokerIds)
        this.submitForm()
      }
      this.brokerFilterTarget.parentElement.parentElement.dataset.hasChanged = false
    })
  }

  initCampaignsSelect() {
    this.updateCampaignOptions()
    $(this.campaignFilterTarget.parentElement.parentElement).on('hide.bs.dropdown', () => {
      if (this.campaignFilterTarget.parentElement.parentElement.dataset.hasChanged == "true") {
        this.updateCampaignExportField();
        this.submitForm()
      }
      this.campaignFilterTarget.parentElement.parentElement.dataset.hasChanged = false
    })
  }

  updateCampaignExportField() {
    if(this.hasCampaignExportFieldTarget) {
      this.campaignExportFieldTarget.value = JSON.stringify(this.retrieveIds(this.campaignFilterTarget))
    }
  }

  updateOfficeOptions(params) {
    if(params == undefined){
      params = {}
    }
    if(params['query'] != undefined && params['query'].length <= 2 && params['query'].length != 0) {
      return
    }

    params['click_event'] = 'campaigns--filters#toggleFilter'
    this.updateOptions(list_offices_campaign_filters_path(), 'offices[]', this.officeFilterTarget, params)
  }

  updateBrandOptions(params) {
    if(params == undefined){
      params = {}
    }
    if(params['query'] != undefined && params['query'].length <= 2 && params['query'].length != 0) {
      return
    }

    params['click_event'] = 'campaigns--filters#toggleFilter'
    this.updateOptions(list_brands_campaign_filters_path(), 'brands[]', this.brandFilterTarget, params)
  }

  updateCategoryOptions(params) {
    if(params == undefined){
      params = {}
    }
    if(params['query'] != undefined && params['query'].length <= 2 && params['query'].length != 0) {
      return
    }

    params['click_event'] = 'campaigns--filters#toggleFilter'
    this.updateOptions(list_categories_campaign_filters_path(), 'categories[]', this.categoryFilterTarget, params)
  }

  updateBrokerOptions(params) {
    if(params == undefined){
      params = {}
    }
    if(params['query'] != undefined && params['query'].length <= 2 && params['query'].length != 0) {
      return
    }

    params['click_event'] = 'campaigns--filters#toggleFilter'
    this.updateOptions(list_brokers_campaign_filters_path(), 'brokers[]', this.brokerFilterTarget, params)
  }

  updateCampaignOptions(params) {
    if(params == undefined){
      params = {}
    }
    if(params['query'] != undefined && params['query'].length <= 2 && params['query'].length != 0) {
      return
    }

    params['click_event'] = 'campaigns--filters#toggleFilter'
    this.updateOptions(list_campaigns_campaign_filters_path(), 'campaigns[]', this.campaignFilterTarget, params)
  }

  updateOptions(route, optionType, target, params) {
    let html = this.retrieveHtml(route, optionType, params)
    target.innerHTML = html
    this.setNumberOfElements(target.parentElement.parentElement.parentElement)
  }

  retrieveHtml(route, optionType, params) {
    let html = ''
    $.ajax(route, {
      data: params,
      dataType: 'html',
      async: false,
      success: (response) => {
        html = response
      },
      error: (xhr, ajaxOptions, thrownError) => {
        console.log(`error setting associations select box: ${thrownError}`)
      }
    })
    return html
  }

  fetchStates() {
    let states = []
    $('[data-state-selector="selected"]').each((index, element) => {
      states.push(element.dataset.state)
    })

    return states
  }

  fetchContentStates() {
    let states = []
    $('[data-content-state-selector="selected"]').each((index, element) => {
      states.push(element.dataset.state)
    })

    return states
  }

  toggleState(stateSelector) {
    if (stateSelector.attr('data-state-selector') == 'selected') {
      stateSelector.attr('data-state-selector', 'deselected')
      stateSelector.removeClass('active')
    } else {
      stateSelector.attr('data-state-selector', 'selected')
      stateSelector.addClass('active')
    }
  }

  toggleContentState(stateSelector) {
    if (stateSelector.attr('data-content-state-selector') == 'selected') {
      stateSelector.attr('data-content-state-selector', 'deselected')
      stateSelector.removeClass('active')
    } else {
      stateSelector.attr('data-content-state-selector', 'selected')
      stateSelector.addClass('active')
    }
  }

  toggleFilter(event) {
    event.stopPropagation()
    event.preventDefault()
    $(event.currentTarget).toggleClass('selector-active')

    let parent = event.currentTarget.parentElement.parentElement.parentElement
    parent.dataset.hasChanged = true
    this.setNumberOfElements(parent)
  }

  setNumberOfElements(parentContainer) {
    let numberOfSelectedElements = $(parentContainer).find('.selector-active').length
    if (numberOfSelectedElements == 0) {
      numberOfSelectedElements = ''
    }
    $(parentContainer).find('.badge').text(numberOfSelectedElements)
  }

  handleSearch(event) {
    let name = event.currentTarget.dataset.name
    switch (name) {
      case 'office':
        return this.updateOfficeOptions({
          query: event.currentTarget.value,
          office_ids: this.retrieveIds(this.officeFilterTarget)
        })
      case 'brand':
        return this.updateBrandOptions({
          query: event.currentTarget.value,
          brand_ids: this.retrieveIds(this.brandFilterTarget)
        })
      case 'category':
        return this.updateCategoryOptions({
          query: event.currentTarget.value,
          category_ids: this.retrieveIds(this.campaignFilterTarget)
        })
      case 'broker':
        return this.updateBrokerOptions({
          office_ids: this.retrieveIds(this.officeFilterTarget),
          broker_ids: this.retrieveIds(this.brokerFilterTarget),
          query: event.currentTarget.value
        })
      case 'campaigns':
        return this.updateCampaignOptions({
          broker_ids: this.retrieveIds(this.brokerFilterTarget),
          campaign_ids: this.retrieveIds(this.campaignFilterTarget),
          query: event.currentTarget.value
        })
    }

  }
}
