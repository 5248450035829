import { ReportsController } from './reports_controller'
import { reports_plugins_finn_index_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['finn', 'spinner']

  connect() {
    this.remoteHTML({
      route: reports_plugins_finn_index_path({shortname: this.shortname}),
      target: this.finnTarget,
      spinner: this.spinnerTarget
    })
  }
}
