import '@stimulus/polyfills'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()

document.addEventListener('turbolinks:before-cache', () => {
  application.controllers.forEach((controller) => {
    if (typeof controller.teardown === 'function') {
      controller.teardown()
    }
  })
})

// The path you may like to change to under `pack` that path will be `./controllers`
// but convention will be in `/app/javascript/controllers`
const context = require.context('controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
