import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form']

  handleNewRoleClick(event) {
    Rails.stopEverything(event)

    const url = event.currentTarget.getAttribute('href');

    $.ajax(url)
      .done((response) => {
        this.modalFormContent.html(response);
        this.modalForm.modal('show');
      })
  }

  handleRoleSubmit(event) {
    Rails.stopEverything(event)

    if (this.formValid) {
      this.beforeSend();
      Rails.fire(this.formTarget, 'submit');
    }
  }

  handleSubmitError(event) {
    const [, , xhr] = event.detail;
    if (xhr.status !== 422) return;
    this.modalFormContent.html(xhr.response);
  }

  beforeSend() {
    const submitButton = this.modalForm.find('input[type="submit"], button[type="submit"]');
    submitButton.html('<i class="fa fa-spinner fa-spin"></i>' + submitButton.text())
    submitButton.attr('disabled', true);
  }

  get formValid() {
    let formIsValid = true;
    this.formTarget.querySelectorAll("[required]").forEach((el) => {
      formIsValid = formIsValid && el.reportValidity();
    })
    return formIsValid
  }

  get modalForm() {
    return $('#modal-form')
  }

  get modalFormContent() {
    return this.modalForm.find('.modal-content')
  }
}
