import {ReportsController} from './reports_controller'

const SWIPE_DEADZONE = 100 // px
const SWIPE_BOUND_LEFT = 12 // px
const SWIPE_BOUND_RIGHT = -112 // px

// Controller that handles lite/power report layout
export default class extends ReportsController {
  static targets = ['liteReport', 'powerReport', 'swipeSurface', 'pageIndicator', 'liteHeader', 'powerHeader']

  connect() {
    this.basePosition = 0
    this.modalContent = document.getElementById('content-body')
    this.locked = false

    this.swipeSurfaceTarget.addEventListener('mousedown', (e) => this.lock(e), false)
    this.swipeSurfaceTarget.addEventListener('touchstart', (e) => this.lock(e), false)
    this.swipeSurfaceTarget.addEventListener('mouseup', (e) => this.release(e), false)
    this.swipeSurfaceTarget.addEventListener('touchend', (e) => this.release(e), false)
    this.swipeSurfaceTarget.addEventListener('mousemove', (e) => this.drag(e), false)

    // TODO: We could perhaps enable this with a deadzone, so it doesn't triggers when users scrolls up/down
    // this.swipeSurfaceTarget.addEventListener('touchmove', (e) => this.drag(e), false)

    this.pageIndicatorTarget.addEventListener('click', (e) => this.handlePageIndicatorClick(e), false)

    window.addEventListener('keydown', (e) => this.handleKeyboardShorcut(e), false)
  }

  lock(event) {
    if (this.lockIsAllowed(event)) {
      this.locked = true
      this.disableTransitions()
      this.lockPosition = this.getPositionX(event)
    }
  }

  drag(event) {
    if (this.locked) {
      let currentPosition = this.getPositionX(event)

      let moveDistance = (currentPosition - this.lockPosition) / window.innerWidth * 100 + this.basePosition

      if (moveDistance > SWIPE_BOUND_LEFT) {
        moveDistance = SWIPE_BOUND_LEFT
      } else if (moveDistance < SWIPE_BOUND_RIGHT) {
        moveDistance = SWIPE_BOUND_RIGHT
      }

      this.liteReportTarget.style.marginLeft = `${moveDistance}vw`

      this.preventTextSelection()
    }
  }

  release(event) {
    if (this.locked) {
      let currentPosition = this.getPositionX(event)
      // User swiped right
      if (currentPosition > this.lockPosition + SWIPE_DEADZONE) {
        this.showLeftSide()

        // User swiped left
      } else if (currentPosition < this.lockPosition - SWIPE_DEADZONE) {
        this.showRightSide()

        // return screen to where it was
      } else {
        this.liteReportTarget.style.marginLeft = `${this.basePosition}vw`
      }
    }

    this.locked = false
  }

  lockIsAllowed(event) {
    if(this.locked){
      return false
    }

    // Don't lock if user is interracting with Chart.js
    if(event.target.nodeName == 'CANVAS'){
      return false
    }

    // Don't lock if modal is open
    if(this.modalContent.innerHTML.length > 0){
      return false
    }
    return true
  }

  getPositionX(event) {
    if (event.screenX !== undefined) {
      return event.screenX
    }

    if (event.touches[0] != null) {
      return event.touches[0].screenX
    }

    return event.changedTouches[0].screenX
  }

  handleKeyboardShorcut(event) {
    switch (event.key) {
      case 'ArrowLeft':
        return this.showLeftSide()
      case 'ArrowRight':
        return this.showRightSide()
    }
  }

  showLeftSide() {
    this.enableTransitions()
    this.basePosition = 0
    this.liteReportTarget.style.marginLeft = `${this.basePosition}vw`
    this.updatePageIndicator('left')
    this.updateHeader('left')
    $('.live-report-components').addClass('height-limited')
  }

  showRightSide() {
    this.enableTransitions()
    this.basePosition = -100
    this.liteReportTarget.style.marginLeft = `${this.basePosition}vw`
    this.updatePageIndicator('right')
    this.updateHeader('right')
    $('.live-report-components').removeClass('height-limited')
  }

  updatePageIndicator(direction) {
    for (var swipeDot of this.pageIndicatorTarget.children) {
      swipeDot.classList.remove('active')
    }

    switch (direction) {
      case 'left':
        return this.pageIndicatorTarget.firstElementChild.classList.add('active')
      case 'right':
        return this.pageIndicatorTarget.lastElementChild.classList.add('active')
      default:
        return false
    }
  }

  updatePageIndicator(direction) {
    switch (direction) {
      case 'left':
        this.pageIndicatorTarget.lastElementChild.classList.remove('active')
        this.pageIndicatorTarget.firstElementChild.classList.add('active')
        break
      case 'right':
        this.pageIndicatorTarget.firstElementChild.classList.remove('active')
        this.pageIndicatorTarget.lastElementChild.classList.add('active')
        break
      default:
        console.log('unknown direction: ' + direction)
        return false
    }
  }

  updateHeader(direction){
    switch (direction) {
      case 'left':
        $(this.liteHeaderTarget).fadeIn()
        $(this.powerHeaderTarget).hide()
        break
      case 'right':
        $(this.powerHeaderTarget).fadeIn()
        $(this.liteHeaderTarget).hide()
        break
      default:
        console.log('unknown direction: ' + direction)
        return false
    }
  }

  handlePageIndicatorClick(event) {
    switch (event.target.dataset.side) {
      case 'left':
        return this.showLeftSide()
      case 'right':
        return this.showRightSide()
    }
  }

  enableTransitions() {
    this.liteReportTarget.style.transition = 'margin 300ms'
  }

  disableTransitions() {
    this.liteReportTarget.style.transition = ''
  }

  preventTextSelection(){
    if (document.selection) {
      document.selection.empty()
    } else {
      window.getSelection().removeAllRanges()
    }
  }
}
