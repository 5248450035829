import {ReportsController} from './reports_controller'
import 'bootstrap/js/dist/modal'

export default class extends ReportsController {
  static targets = ['modal', 'spinner', 'mainAction']

  show(dataTags) {
    this.setDataTags(dataTags)
    this.spinner.removeClass('d-none')
    this.modal.modal('show')
  }

  exit() {
    if (this.nestedHtml) {
      this.modalBody[0].innerHTML = this.nestedHtml
      this.surface()
    } else {
      this.hide()
    }
  }

  hide() {
    this.modal.modal('hide')
    this.removeDataTags()
    this.removeContent()
    this.spinner.addClass('d-none')
  }

  nest(nestedHtml) {
    this.nestedHtml = nestedHtml
    let icon = this.mainAction.find('i')
    icon.addClass('fa-arrow-left').removeClass('fa-times')
  }

  surface() {
    this.nestedHtml = null
    let icon = this.mainAction.find('i')
    icon.addClass('fa-times').removeClass('fa-arrow-left')
  }

  loaded(event) {
    this.spinner.addClass('d-none')
  }

  setDataTags(tags) {
    let attributes = {}
    for (var key in tags) {
      attributes['data-' + key] = tags[key]
    }
    this.modalBody.attr(attributes)
  }

  removeDataTags() {
    $.each(this.modalBody[0].dataset, (i) => {
      this.modalBody.removeAttr('data-' + i)
    })
  }

  removeContent() {
    this.modalBody.empty()
  }

  get modal() {
    return $(this.modalTarget)
  }

  get modalBody() {
    return $('#content-body', this.modal)
  }

  get spinner() {
    return $(this.spinnerTarget)
  }

  get mainAction() {
    return $(this.mainActionTarget)
  }
}
