import { Controller } from 'stimulus'
import $ from 'jquery'
import 'jquery.typewatch'
import '../../infinite-scroller/index.js'
import { new_campaign_path, properties_campaigns_path, property_path } from 'routes.js.erb'

export default class extends Controller {
  static targets = ['wrap', 'searchInput', 'spinner', 'searchSpinner']

  initialize() {
    this.showSpinner(this.spinner, false)
    this.showSpinner(this.searchSpinner, false)
  }

  connect() {
    if (!this.shouldPopulate()) return

    this.populateProperties(() => { this.registerInfiniteScroller() })
    this.registerSearch()
  }

  registerInfiniteScroller() {
    if (this.searchInput.val() != '') return

    let url = this.path + '?source=' + this.source
    if (this.sold !== '') url += '&sold=' + this.sold

    InfiniteScroller(
      this.wrap[0],
      { url: url }
    )
  }

  registerSearch() {
    var lastValue,
      lastRequest

    this.searchInput.typeWatch({
      callback: () => {
        if (lastRequest != null) {
          lastRequest.abort()
          lastRequest = null
        }

        let currentValue = this.searchInput.val()
        if (currentValue == lastValue) return
        // TODO: Make this play nice with infiniteScroller and pagination?
        if (currentValue != '' && this.infiniteScroller) {
          this.infiniteScroller.destroy()
        } else if (currentValue == '') {
          if (this.infiniteScroller) {
            this.infiniteScroller.currentPage = 1
          } else {
            let url = this.path + '?source=' + this.source
            if (this.sold !== '') url += '&sold=' + this.sold

            InfiniteScroller(
              this.wrap[0],
              { url: url }
            )
          }
        }
        var data = { source: this.source, q: currentValue }
        if (this.sold !== '') data['sold'] = this.sold
        lastValue = currentValue
        lastRequest = this.getProperties({
          data: data,
          beforeSend: () => {
            this.wrap.empty()
            this.showSpinner(this.searchSpinner, true)
          },
          success: (response, status, xhr) => {
            this.showSpinner(this.searchSpinner, false)
            if (response.data) {
              this.wrap.html(response.data)
            } else {
              this.wrap.html('<p>' + I18n.t('campaigns.choose_property.no_results') + '</p>')
            }
          },
          error: (evt, xhr, status, error) => {
            if (status == 'abort') return

            this.showSpinner(this.searchSpinner, false)
            // TODO: Handle errors here
            console.log('Error: ' + error)
            if (xhr.responseJSON.location) {
              window.location.href = xhr.responseJSON.location
              return
            }
            alert(I18n.t('errors.general'))
          }
        })
      }
    })
  }

  populateProperties(callback) {
    var data = { source: this.source }
    if (this.searchInput.val() != '') data['q'] = this.searchInput.val()
    if (this.sold !== '') data['sold'] = this.sold

    let args = {
      beforeSend: () => {
        this.wrap.empty()
        this.showSpinner(this.spinner, true)
      },
      success: (response, status, xhr) => {
        this.showSpinner(this.spinner, false)
        if (response && response.data) {
          this.wrap.append(response.data)
        } else {
          this.wrap.html('<p>' + I18n.t('campaigns.choose_property.no_results') + '</p>')
        }
        if (callback) callback()
      },
      error: (evt, xhr, status, error) => {
        this.showSpinner(this.spinner, false)
        // TODO: Handle errors here
        console.log('Error: ' + error)
        if (xhr.responseJSON.location) {
          window.location.href = xhr.responseJSON.location
          return
        }
        alert(I18n.t('errors.general'))
      }
    }
    Object.assign(args, data ? { data: data } : null)
    this.getProperties(args)
  }

  handleSelection(event) {
    let element = $(event.currentTarget)
    let category = (this.sold == true) ? 'agent_manual' : 'property'
    let params = '?property_id=' + element.data('property-id') + '&category=' + category
    window.location = new_campaign_path() + params + '&source=' + this.source
  }

  getProperties(args) {
    if (typeof args === 'undefined' || typeof args.success === 'undefined') {
      throw new Error('function "getProperties" requires success callback.')
    }
    return $.ajax(this.path, args)
  }

  shouldPopulate() {
    return !$('.card', this.wrap).length
  }

  showSpinner(spinner, show) {
    show ? spinner.show() : spinner.hide()
  }

  get infiniteScroller() {
    return this.wrap[0].infiniteScroller
  }

  get spinner() {
    return $(this.spinnerTarget)
  }

  get searchSpinner() {
    return $(this.searchSpinnerTarget)
  }

  get searchInput() {
    return $(this.searchInputTarget)
  }

  get wrap() {
    return $(this.wrapTarget)
  }

  get source() {
    return this.wrap.data('source')
  }

  get sold() {
    return this.wrap.data('sold')
  }

  get path() {
    return properties_campaigns_path()
  }
}
