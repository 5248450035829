import { Controller } from 'stimulus'
import $ from 'jquery'

export default class ConsentsController extends Controller {
  static targets = ['opener']

  connect() {
    this.setupConsentModal()
  }

  setupConsentModal() {
    $(this.openerTarget).on('click', (event) => {
      event.preventDefault()
      $('#consent-modal').modal('show')
    })

    $('[data="consent-toggle"]').on('change', (event) => {
      event.preventDefault()
      let submitButton = document.querySelector('[data-consent]')
      submitButton.dataset.consent = event.currentTarget.checked

      if (submitButton.dataset.consent == 'true') {
        submitButton.innerHTML = 'Fortsett'
      } else {
        submitButton.innerHTML = 'Fortsett uten alle samtykker'
      }
    })

    $('[data="promo-consent-toggle"]').on('change', (event) => {
      event.preventDefault()
      let submitButton = document.querySelector('[data-consent]')
      submitButton.dataset.promoConsent = event.currentTarget.checked
      let consentToggle = $('[data="consent-toggle"]')

      if(event.currentTarget.checked) {
        consentToggle.prop('disabled', false);
      } else{
        consentToggle.prop('checked', false);
        consentToggle.prop('disabled', true);
        submitButton.dataset.consent = false
      }
    })

    $('[data-consent-open]').on('click', (event) => {
      event.preventDefault()
      $(event.currentTarget).hide()
      $('[data-consent-control]').show()
    })

    $('[data-consent]').on('click', (event) => {
      event.preventDefault()
      $('[data="consent-setting"]').val(event.currentTarget.dataset.consent)
      $('[data="promo-consent-setting"]').val(event.currentTarget.dataset.promoConsent)
      $('input[type="submit"]').click()
    })
  }
}