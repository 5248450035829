import { ReportsController } from './reports_controller'
import { reports_overview_networks_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['networks', 'spinner']

  connect() {
    this.remoteHTML({
      route: reports_overview_networks_path({ shortname: this.shortname }),
      target: this.networksTarget,
      spinner: this.spinnerTarget
    })
  }
}
