import { ReportsController } from './reports_controller'
import MarketerChart from '../../marketer-chart/standard'
import 'bootstrap/js/dist/tooltip'
import { reports_overview_traffic_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['traffic', 'spinner']

  connect() {
    this.remoteHTML({
      route: reports_overview_traffic_path({shortname: this.shortname}),
      target: this.trafficTarget,
      spinner: this.spinnerTarget,
      callback: () => {
        let chartElement = document.getElementById('chart-traffic')

        if (chartElement != undefined) {
          new MarketerChart('chart-traffic')
          $('[data-toggle="tooltip"]').tooltip()
        }
      }
    })
  }
}
