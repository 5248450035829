import { ReportsController } from './reports_controller'
import MarketerChart from '../../marketer-chart/standard'
import { reports_details_target_group_path, reports_switch_target_group_path } from 'routes.js.erb'

export default class extends ReportsController {
  connect() {
    this.displayTargetGroup(undefined,0)
  }

  displayTargetGroup(event, index) {
    this.currentIndex = index !== undefined ? index : event.currentTarget.dataset.index
    this.remoteHTML({
      route: reports_details_target_group_path({shortname: this.shortname}),
      params: {
        index: this.currentIndex,
        count: document.querySelector('.overview-target-groups').dataset.targetGroupCount
      },
      target: this.modalBody[0],
      callback: () => {
        this.dispatchCustomEvent('modal-loaded')
        this.enableCarousel()
        this.initChart(this.currentIndex)
      }
    })
  }

  enableCarousel() {
    $('[data-carousel="target-group"]').slick({
      arrows: true,
      prevArrow: $('#left-arrow'),
      nextArrow: $('#right-arrow'),
      dots: true,
      initialSlide: parseInt(this.currentIndex),
      infinite: false,
    }).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      this.switchTargetGroup(nextSlide)
    })
  }

  switchTargetGroup(adIndex) {
    this.remoteHTML({
      route: reports_switch_target_group_path({
        shortname: this.shortname,
      }),
      params: {
        index: adIndex
      },
      target: $(`[data-ad-index="${adIndex}"]`)[0],
      callback: () => {
        this.initChart(adIndex, 0)
      }
    })
  }

  initChart(adIndex, animationDuration) {
    let chart = new MarketerChart(`target-group-chart-${adIndex}`, animationDuration)
    setTimeout(() => {
      let lastElementIndex = chart.getDatasetMeta(0).data.length - 1
      chart.openTooltip(lastElementIndex)
    }, 700)
  }
}
