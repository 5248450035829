import { ReportsController } from './reports_controller'
import MarketerChart from '../../marketer-chart/standard'
import { reports_details_traffic_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['results', 'spinner', 'totalLabel', 'totalValue', 'metric']

  connect() {
    this.remoteHTML({
      route: reports_details_traffic_path({shortname: this.shortname, component: 'lite'}),
      target: this.resultsTarget,
      spinner: this.spinnerTarget,
      callback: () => {
        let chart = new MarketerChart('traffic-lite-chart')
        this.metricLabel = this.metricTarget.innerHTML
        this.setupChartLegend(chart)
        this.setupChartPanIcon(chart)
      }
    })
  }

  setupChartLegend(chart) {
    let legend = document.getElementById('traffic-lite-legend')
    legend.innerHTML = chart.generateLegend()

    let legendItems = document.querySelectorAll('#traffic-lite-legend li')
    for (let [index, item] of legendItems.entries()) {
      item.onclick = () => {
        this.setAsHidden(chart, index, item)
        this.updateTotals(item, index)
        chart.update()
      }
    }
  }

  setupChartPanIcon(chart) {
    setTimeout(() => {
      let indicator = document.getElementById('traffic-lite-pan-indicator')
      $(indicator).fadeIn(100).animate({left: '4%'}).animate({left: '-4%'}).animate({left: 0}).fadeOut(500)
    }, 1500)
  }

  setAsHidden(chart, index, item) {
    let isHidden = !chart.getDatasetMeta(index).hidden
    chart.getDatasetMeta(index).hidden = isHidden

    if (isHidden) {
      item.classList.add('deactivated')
    } else {
      item.classList.remove('deactivated')
    }
  }

  updateTotals(item, index) {
    let sum = 0
    let activePlatformNames = []
    let activePlatforms = document.querySelectorAll('#traffic-lite-legend li:not(.deactivated)')
    let totalPlatforms = document.querySelectorAll('#traffic-lite-legend li')

    for (let platform of activePlatforms) {
      let platformName = platform.innerHTML.split('</span>')[1]
      activePlatformNames.push(platformName)

      let platformTotal = document.querySelector(`[data-total-name="${platformName}"]`).innerHTML
      sum += parseInt(platformTotal.replace(' ', ''))
    }

    let formattedSum = sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    this.totalValueTarget.innerHTML = formattedSum

    if (activePlatforms.length < totalPlatforms.length && activePlatforms.length > 0) {
      this.totalLabelTarget.innerHTML = `Totalt antall ${this.metricLabel} fra ${activePlatformNames.join(' og ')}`
    } else {
      this.totalLabelTarget.innerHTML = `Totalt antall ${this.metricLabel}`
    }
  }
}
