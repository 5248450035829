$(document).on('turbolinks:load', () => {
	$(document).ajaxStart(function(event, request) {
		$('#flash').empty()
	}).ajaxComplete(function(evt, xhr) {
		let cssClasses = {
			Notice: 'success',
			Warning: 'warning',
			Error: 'danger'
		}

		$(['Notice', 'Warning', 'Error']).each(function(index, type) {
			let msg = xhr.getResponseHeader('X-Ajax-'+type)
			if (msg) $('#flash').append('<div class="alert alert-' + cssClasses[type] + '">' + decodeURIComponent(escape(msg)) + '</div>')
		})
	})
})
