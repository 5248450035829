import { ReportsController } from './reports_controller'
import { reports_overview_metrics_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['results', 'spinner']

  connect() {
    this.remoteHTML({
      route: reports_overview_metrics_path({ shortname: this.shortname }),
      target: this.resultsTarget,
      spinner: this.spinnerTarget
    })
  }
}
