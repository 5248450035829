import { ReportsController } from './reports_controller'
import { reports_header_main_info_path, reports_header_advanced_info_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['info', 'spinner', 'powerInfo', 'powerSpinner']

  connect() {
    this.remoteHTML({
      route: reports_header_main_info_path({shortname: this.shortname}),
      target: this.infoTarget,
      spinner: this.spinnerTarget
    })

    this.remoteHTML({
      route: reports_header_advanced_info_path({shortname: this.shortname}),
      target: this.powerInfoTarget,
      spinner: this.powerSpinnerTarget
    })
  }
}
