import { Controller } from 'stimulus'
import { campaign_packages_campaigns_path } from 'routes.js.erb'

export default class extends Controller {
  static targets = ['modal', 'modalUpgrade', 'form', 'hiddenInput', 'wrap', 'spinner']

  connect() {
    this.modal.on('shown.bs.modal', () => { this.getCampaignPackages() })
    if (this.upgradePackageId != '') this.showUpgradeModal()
  }

  upgrade(campaignPackageId) {
    this.hiddenInput.val(campaignPackageId)
    Rails.fire(this.form[0], 'submit')
  }

  handleSelection(event) {
    this.submitSelection($(event.currentTarget).data('package-id'))
  }

  submitSelection(campaignPackageId) {
    this.hiddenInput.val(campaignPackageId)
    Rails.fire(this.form[0], 'submit')
  }

  submitSucceeded() {
    window.location.hash = ''
    location.reload()
  }

  submitFailed() { alert(I18n.t('errors.general')) }

  getCampaignPackages() {
    $.ajax(campaign_packages_campaigns_path(), {
      data: { campaign_category_id: this.campaignCategoryId },
      beforeSend: () => {
        this.teardown()
        this.showSpinner(true)
      },
      success: (response, status, xhr) => {
        this.showSpinner(false)
        if (response.data) {
          this.wrap.html(response.data)
          $('#campaign_campaign_package_id_' + this.currentCampaignPackageId + ' .selection-indicator').attr('hidden', false)
        }
      },
      error: (evt, xhr, status, error) => {
        if (status == 'abort') return

        this.showSpinner(false)
        // TODO: Handle errors here
        console.log('Error: ' + error)
        if (xhr.responseJSON.location) {
          window.location.href = xhr.responseJSON.location
          return
        }
        alert(I18n.t('errors.general'))
      }
    })
  }

  showUpgradeModal() {
    window.history.pushState(null, null, window.location.pathname)
    this.modalUpgrade.modal('show')
  }

  handleUpgrade(event) { this.submitSelection(this.upgradePackageId) }

  showSpinner(show) { show ? this.spinner.show() : this.spinner.hide() }

  teardown() { this.wrap.empty() }

  get modal() { return $(this.modalTarget) }
  get form() { return $(this.formTarget) }
  get hiddenInput() { return $(this.hiddenInputTarget) }
  get wrap() { return $(this.wrapTarget) }
  get spinner() { return $(this.spinnerTarget) }

  get campaignCategoryId() { return this.wrap.data('campaign-category-id') }
  get currentCampaignPackageId() { return this.wrap.data('current-campaign-package-id') }

  get modalUpgrade() { return $(this.modalUpgradeTarget) }
  get upgradePackageId() { return this.modalUpgrade.data('campaign-package-id') }
}
