import { ReportsController } from './reports_controller'
import { reports_faqs_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['info', 'spinner']

  connect() {
    this.remoteHTML({
      route: reports_faqs_path({shortname: this.shortname}),
      target: this.modalBody[0],
      callback: () => {
        this.dispatchCustomEvent('modal-loaded')
      }
    })
  }
}
