$(document).on('turbolinks:load', () => {
	$('body').on('click', 'tr[data-href]', function(event) {
		var element = $(this),
				href = element.data('href')

		if (event.ctrlKey || event.metaKey) {
			window.open(href)
		} else {
			document.location = href
		}
		// TODO: Don't apply listener here, just check what was clicked and if a then stop propagation
		$('a', element).on('click', function(e) { e.stopPropagation() })
	})
})
