import {ReportsController} from './reports_controller'
import { reports_overview_target_groups_path } from 'routes.js.erb'

export default class extends ReportsController {
  static targets = ['targetGroup', 'spinner']

  connect() {
    this.remoteHTML({
      route: reports_overview_target_groups_path({ shortname: this.shortname }),
      target: this.targetGroupTarget,
      spinner: this.spinnerTarget
    })
  }
}
