import {Controller} from 'stimulus'
import $ from "jquery"
import 'slick-carousel/slick/slick.min'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import 'reports/components/delta-preview/dnb/scss/agent'
import { agent_ads_examples_campaigns_path } from 'routes.js.erb'

export default class AdsExamplesController extends Controller {
  static targets = ['wrapper']

  connect() {
    $.get(agent_ads_examples_campaigns_path(), {}, 'json')
      .done((html) => {
        this.wrapperTarget.innerHTML = html
        $('#overview-ads').slick({
          arrows: true,
          prevArrow: $('#left-arrow'),
          nextArrow: $('#right-arrow'),
          dots: true
        })
      })
      .fail((e) => {
        console.log('build view failed')
      })
  }
}
