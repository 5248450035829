import { ReportsController } from './reports_controller'
import MarketerChart from '../../marketer-chart/standard'
import { reports_details_metrics_path } from 'routes.js.erb'

export default class extends ReportsController {
  connect() {
    this.remoteHTML({
      route: reports_details_metrics_path({shortname: this.shortname}),
      params: {metric: this.metric},
      target: this.modalBody[0],
      callback: () => {
        this.dispatchCustomEvent('modal-loaded')
        let chart = new MarketerChart('metric-details-chart')
        setTimeout(() => {
          let lastElementIndex = chart.getDatasetMeta(0).data.length - 1
          chart.openTooltip(lastElementIndex)
        }, 700)
      }
    })
  }

  get metric() {
    return this.modalBody.attr('data-metric')
  }
}
