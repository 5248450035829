import { ReportsController } from './reports_controller'
import MarketerChart from '../../marketer-chart/standard'
import { reports_details_network_path, reports_switch_network_path } from 'routes.js.erb'

export default class extends ReportsController {
  connect() {
    this.displayNetwork(undefined, 0)
  }

  displayNetwork(event, index) {
    this.currentIndex = index !== undefined ? index : event.currentTarget.dataset.index
    this.remoteHTML({
      route: reports_details_network_path({
        shortname: this.shortname,
      }),
      params: {
        index: this.currentIndex,
        count: document.getElementById('overview-networks').dataset.networkCount
      },
      target: this.modalBody[0],
      callback: () => {
        this.dispatchCustomEvent('modal-loaded')
        this.enableCarousel()
        this.initChart(this.currentIndex)
      }
    })
  }

  enableCarousel() {
    $('[data-carousel="network"]').slick({
      arrows: true,
      prevArrow: $('#left-arrow'),
      nextArrow: $('#right-arrow'),
      dots: true,
      initialSlide: parseInt(this.currentIndex),
      infinite: false,
    }).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      this.switchNetwork(nextSlide)
    })
  }

  switchNetwork(networkIndex) {
    this.remoteHTML({
      route: reports_switch_network_path({
        shortname: this.shortname,
      }),
      params: {
        index: networkIndex
      },
      target: $(`[data-ad-index="${networkIndex}"]`)[0],
      callback: () => {
        this.initChart(networkIndex, 0)
      }
    })
  }

  initChart(networkIndex, animationDuration) {
    let chart = new MarketerChart(`network-chart-${networkIndex}`, animationDuration)
    setTimeout(() => {
      let lastElementIndex = chart.getDatasetMeta(0).data.length - 1
      chart.openTooltip(lastElementIndex)
    }, 700)
  }
}
