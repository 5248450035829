import $ from "jquery";
import "daterangepicker/daterangepicker.js";
import MarketerDatePicker from "../../../marketer-date-picker";
import { Controller } from "stimulus";
import {
  list_clients_admin_orders_path,
  list_brands_admin_orders_path,
  export_admin_orders_path,
} from "routes.js.erb";

export default class FiltersController extends Controller {
  static targets = [
    "form",
    "clientsFilter",
    "brandFilter",
    "dateToField",
    "dateFromField",
    "searchForm",
    "searchField",
    "dateRange",
    "statesForm",
  ];

  connect() {
    this.initCalendar();
    this.initClientsSelect();
    this.initBrandsSelect();
    this.initSearch();
    this.initStatesSelect();
    this.setupSubmit();
  }

  initSearch() {
    $(this.searchFormTarget).on("submit", (event) => {
      event.preventDefault();
      this.submitForm();
    });
  }

  initStatesSelect() {
    $(this.statesFormTarget).on("submit", (event) => {
      event.preventDefault();
      var elem = $(event.originalEvent.submitter);
      $(`.${elem.attr("class")}`).removeClass("active");
      elem.toggleClass("active");
      this.submitForm();
    });
  }

  setupSubmit() {
    $(this.formTarget).on("submit", (event) => {
      event.preventDefault();
      this.submitForm();
    });
  }

  export(event) {
    if (typeof event.detail[0] !== 'undefined' && event.detail[0] !== true) return;

    $.post(export_admin_orders_path(), {
      date_from: this.dateFromFieldTarget.value,
      date_to: this.dateToFieldTarget.value,
      client_ids: this.retrieveIds(this.clientsFilterTarget),
      brand_ids: this.retrieveIds(this.brandFilterTarget),
      query: this.searchFieldTarget.value,
      states: this.fetchStates(),
      purpose: event.currentTarget.value,
    }).fail(function () {
      alert(I18n.t("errors.general"));
    });
  }

  submitForm() {
    let listController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller~="admin--orders--list"]'),
      "admin--orders--list"
    );

    let page = $(".page-item.active:first .page-link").html();

    listController.refresh({
      date_from: this.dateFromFieldTarget.value,
      date_to: this.dateToFieldTarget.value,
      client_ids: this.retrieveIds(this.clientsFilterTarget),
      brand_ids: this.retrieveIds(this.brandFilterTarget),
      query: this.searchFieldTarget.value,
      states: this.fetchStates(),
      page: page ? page : 1,
    });
  }

  retrieveIds(target) {
    let ids = [];
    for (let option of target.children) {
      if (option.classList.contains("selector-active")) {
        ids.push(option.dataset.id);
      }
    }
    return ids;
  }

  setSwitcher(event) {
    event.preventDefault();
    $(".group-selector .dropdown-item").removeClass("active");
    $(event.currentTarget).addClass("active");
    $(".group-selector .badge").text(event.currentTarget.value.toLowerCase());

    this.submitForm();
  }

  initCalendar() {
    new MarketerDatePicker(
      $(this.dateRangeTarget),
      $(this.dateFromFieldTarget),
      $(this.dateToFieldTarget)
    ).init();
    $(this.dateToFieldTarget).on("change", () => {
      this.submitForm();
    });

    $(this.dateFromFieldTarget).on("change", () => {
      this.submitForm();
    });
  }

  initClientsSelect() {
    if (this.clientsFilterTarget.dataset.disabled) {
      return;
    }

    this.updateClientsOptions({
      client_ids: this.retrieveIds(this.clientsFilterTarget),
    });
    $(this.clientsFilterTarget.parentElement.parentElement).on(
      "hide.bs.dropdown",
      () => {
        if (
          this.clientsFilterTarget.parentElement.parentElement.dataset
            .hasChanged == "true"
        ) {
          const clientIds = this.retrieveIds(this.clientsFilterTarget);

          this.submitForm();
        }
        this.clientsFilterTarget.parentElement.parentElement.dataset.hasChanged = false;
      }
    );
  }

  initBrandsSelect() {
    if (this.brandFilterTarget.dataset.disabled) {
      return;
    }

    this.updateBrandOptions({
      brand_ids: this.retrieveIds(this.brandFilterTarget),
    });
    $(this.brandFilterTarget.parentElement.parentElement).on(
      "hide.bs.dropdown",
      () => {
        if (
          this.brandFilterTarget.parentElement.parentElement.dataset
            .hasChanged == "true"
        ) {
          this.submitForm();
        }
        this.brandFilterTarget.parentElement.parentElement.dataset.hasChanged = false;
      }
    );
  }

  updateClientsOptions(params) {
    if (params == undefined) {
      params = {};
    }
    if (
      params["query"] != undefined &&
      params["query"].length <= 2 &&
      params["query"].length != 0
    ) {
      return;
    }

    params["click_event"] = "admin--orders--filters#toggleFilter";
    this.updateOptions(
      list_clients_admin_orders_path(),
      "clients[]",
      this.clientsFilterTarget,
      params
    );
  }

  updateBrandOptions(params) {
    if (params == undefined) {
      params = {};
    }
    if (
      params["query"] != undefined &&
      params["query"].length <= 2 &&
      params["query"].length != 0
    ) {
      return;
    }

    params["click_event"] = "admin--orders--filters#toggleFilter";
    this.updateOptions(
      list_brands_admin_orders_path(),
      "brands[]",
      this.brandFilterTarget,
      params
    );
  }

  updateOptions(route, optionType, target, params) {
    let html = this.retrieveHtml(route, optionType, params);
    target.innerHTML = html;
    this.setNumberOfElements(target.parentElement.parentElement.parentElement);
  }

  retrieveHtml(route, optionType, params) {
    let html = "";
    $.ajax(route, {
      data: params,
      dataType: "html",
      async: false,
      success: (response) => {
        html = response;
      },
      error: (xhr, ajaxOptions, thrownError) => {
        console.log(`error setting associations select box: ${thrownError}`);
      },
    });
    return html;
  }

  toggleFilter(event) {
    event.stopPropagation();
    event.preventDefault();
    $(event.currentTarget).toggleClass("selector-active");

    let parent = event.currentTarget.parentElement.parentElement.parentElement;
    parent.dataset.hasChanged = true;
    this.setNumberOfElements(parent);
  }

  setNumberOfElements(parentContainer) {
    let numberOfSelectedElements =
      $(parentContainer).find(".selector-active").length;
    if (numberOfSelectedElements == 0) {
      numberOfSelectedElements = "";
    }
    $(parentContainer).find(".badge").text(numberOfSelectedElements);
  }

  handleFilterSearch(event) {
    let name = event.currentTarget.dataset.name;
    switch (name) {
      case "client":
        return this.updateClientsOptions({
          query: event.currentTarget.value,
          client_ids: this.retrieveIds(this.clientsFilterTarget),
        });
      case "brand":
        return this.updateBrandOptions({
          query: event.currentTarget.value,
          brand_ids: this.retrieveIds(this.brandFilterTarget),
        });
    }
  }

  fetchStates() {
    return [$(".active", this.statesFormTarget).val()];
  }
}
